import React from "react"
import "./WhoWeAre.css"

import { Link } from "gatsby"

export default class WhoWeAre extends React.Component {
  render() {
    return (
      <div id="self-praise">
        <div id="self-praise__content-wrapper"></div>
        <div className="self-praise__child">
          <h2 className="self-praise__title">Who we are</h2>
        </div>

        <div id="self-praise__para">
          <p>
            Whitlock Design Group is a dynamic firm with a reputation for
            pioneering change and innovation within the retail, restaurant and
            entertainment industries.
          </p>
          <p>
            Our fresh and trend setting approach tells a unique story about each
            project, with cutting edge architecture and interior design
            concepts.
          </p>{" "}
        </div>
        <Link to="/about-us/" className="link">
          <button className="btn">Read more </button>
        </Link>
      </div>
    )
  }
}
